import React from 'react';
import { Container } from './nav.css';
import GradientLink from '../../gradient-link';

const Nav = () => (
  <Container>
    <ul>
      <GradientLink
        href="https://app.businessmadesimple.com/sign-up/guide-coach-invitees"
        target="_blank"
        rel="noopener"
        tabindex="0"
      >
        <p className="no-margin">
          Get <span className="hide-tablet">Business Made Simple</span>
          <span className="hide-desktop">Access</span>
        </p>
      </GradientLink>
    </ul>
  </Container>
);

export default Nav;
